import { memo } from 'react';
import OutlineText from './OutlineText';

const PlayAgainSection = memo(function PlayAgainSection({ styles, show, onNewGame, onGoToLobby }) {
    //const showWinnersSection = [GameStates.EndGame, GameStates.PlayAgain].includes(gamesState);
    return (
        <div className={`${styles.playAgainButtonSection} ${show && styles.show}`}>
            <button className={`${styles.mainButton}`} onClick={onNewGame} tabIndex='-1'>
                <OutlineText styles={styles} text="Play Again" />
            </button>
            <button className={`${styles.mainButton} ${styles.alt}`} onClick={onGoToLobby} tabIndex='-1'>
                <OutlineText styles={styles} text="Return to lobby" />
            </button>
        </div>
    )
});

export default PlayAgainSection;