/* eslint-disable no-unused-vars */
import * as Colyseus from "colyseus.js";
import { Howl, Howler } from "howler";
import * as Sentry from "@sentry/react";
//components
import React, { Component } from 'react';
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Loading from "components/Loading";
import Menu from "components/Menu";
import Player from "components/Player";
import ReactPlayer from 'react-player'
import MoneyDrop from "components/hostSubComponents/MoneyDrop";
import RoomDetails from "components/hostSubComponents/RoomDetails";
import RoundIntro from "components/hostSubComponents/RoundIntro";
import GameModeSelection from "components/hostSubComponents/GameModeSelection";
import TeamsSelection from "components/hostSubComponents/TeamsSelection";
import RoundSummary from "components/hostSubComponents/RoundSummary";
import WinnerSection from "components/hostSubComponents/WinnerSection";
import PlayAgainSection from "components/hostSubComponents/PlayAgainSection";

//styles
import "animate.css";
import styles from 'components/HostStyles.module.scss';

//images
import logo from "images/logo.png";
import BonusBoard from "images/BonusBoard.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import Trophy from "images/Trophy.png";
import CatchphraseScreen from "images/CatchphraseScreen.png";
import BG from "images/BG.png";
import studioBG from "images/Background Studio.png";

//SFX
import { audio } from "constants/audio";

//constants
import { GameStates, CatchphraseServerOutMessages, CatchphraseServerInMessages } from "constants/constants"
import { testRoomState } from "constants/testData";

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

const gameId = "catchphrase";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: {},
            roomState: testRoomState, //Debug
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true, //Debug
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: true,

            players: [],
            playersRequired: 2,
            showPlayers: false,

            doingTutorial: false,
            showTutorial: false,
            skipTutorialCount: 0,
            showStartButtons: false,
            showPlayAgainButtons: false,
            showTeamsSection: false,
            hideVideo: true,

            videoReady: false,
            showCatchphrase: false,
        };

        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        // create ref for the video player
        this.videoPlayer = React.createRef();
        //this.sleepController = new AbortController();
    }

    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    componentDidMount() {
        this.setTags();
        this.initAudio();
        this.doReconnect();
        //this.toggleMute(true, false);

        //Dev only
        if (window.location.hostname === "localhost") {
            setInterval(() => {
                var iframes = document.querySelectorAll('iframe');
                if (iframes.length > 0) console.log("Developer: ", "react iframe found, trying to remove it");
                for (var i = 0; i < iframes.length; i++) {
                    iframes[i].parentNode.removeChild(iframes[i]);
                }
            }, 1000);
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    componentWillUnmount() {
        Howler.stop();
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playAudio(audio.Music.Menu, true, 0.15);
    }

    preloadAudio() {
        this.loadAudioCollection(audio.SFX);
        this.loadAudioCollection(audio.Music);
        this.loadAudioCollection(audio.VO);
    }

    loadAudioCollection(collection) {
        for (let key in collection) {
            console.log("Preloading audio", key);
            const sound = collection[key];
            if (sound.variants) {
                sound.currentVariantIndex = Math.floor(Math.random() * sound.variants.length);
                sound.variants.forEach((variant) => {
                    this.loadAudio(variant);
                });
            } else this.loadAudio(sound);
        }
    }

    loadAudio(sound) {
        sound.loaded = new Howl({
            src: sound.import,
            preload: true,
            loop: sound.loop,
            volume: sound.volume,
            pool: sound.preventMultiple ? 1 : 5,
        });
    }

    playAudio(sound) {
        if (!sound) {
            console.warn("No sound provided to playAudio");
            return;
        }
        let audioToPlay = null;
        if (sound.variants) {
            const playing = sound.variants.some((variant) => variant.loaded.playing());
            if (playing && sound.preventMultiple) return;
            audioToPlay = sound.variants[sound.currentVariantIndex];
            console.log("audio to play", audioToPlay);
            console.log("sound", sound);
            sound.currentVariantIndex = (sound.currentVariantIndex + 1) % sound.variants.length;
        } else {
            if (sound.loaded.playing() && sound.preventMultiple) return;
            audioToPlay = sound;
        }

        if (audioToPlay?.loaded) {
            audioToPlay.loaded.play();
            audioToPlay.loaded.volume(audioToPlay.volume);
        }
        else console.warn(`Audio ${sound} not loaded`);
    }

    stopAudio(sound) {
        const sounds = sound.variants ? sound.variants : [sound];
        sounds.forEach((s) => { s.loaded?.stop();});
    }

    fadeOutAudio(sound, duration) {
        const sounds = sound.variants ? sound.variants : [sound];
        sounds.forEach((s) => { s.loaded?.fade(s.volume, 0, duration); });
    }

    fadeInAudio(sound, duration) {
        const sounds = sound.variants ? sound.variants : [sound];
        sounds.forEach((s) => { s.loaded?.fade(0, s.volume, duration); });
    }

    toggleFullScreen = () => {
        this.playAudio(audio.SFX.ButtonPress);
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        this.playAudio(audio.SFX.ButtonPress);
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.playAudio(audio.SFX.ButtonPress);
        this.setState({ muted: newVal, });
    }

    signalStartGame = async () => {
        this.playAudio(audio.SFX.ButtonPress);
        this.setState({ showStartButtons: false });
        this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial });
        
    }

    signalGoToLobby = () => {
        this.playAudio(audio.SFX.ButtonPress);
        this.state.room.send("change_game", {});
    }

    signalStartSolos = async () => {
        this.stopAudio(audio.VO.Welcome);
        this.playAudio(audio.SFX.ButtonPress);
        this.setState({ showStartButtons: false, showTeamsSection: false, showGameSection: false });
        await this.sleep(500);
        this.state.room.send(CatchphraseServerInMessages.FinishGameSelection, { gameType: "solos" });
    }

    signalStartTeams = async () => {
        this.stopAudio(audio.VO.Welcome);
        this.playAudio(audio.SFX.ButtonPress);
        this.setState({ showGameSection: false });
        await this.sleep(500);
        this.setState({ showTeamsSection: true });
        this.state.room.send(CatchphraseServerInMessages.FinishGameSelection, { gameType: "teams" });
    }

    signalNewGame = async () => {
        this.playAudio(audio.SFX.ButtonPress);
        this.stopAudio(audio.VO.Winners);
        this.fadeOutAudio(audio.Music.EndGame, 1000);
        this.setState({ showPlayAgainButtons: false, showWinnersSection: false, });
        await this.sleep(500);
        this.state.room.send(CatchphraseServerInMessages.PlayAgain, {});
    }

    signalFinishTeamsSelection = async () => {
        this.playAudio(audio.SFX.ButtonPress);
        this.stopAudio(audio.VO.TeamSelect);
        this.setState({ showTeamsSection: false, });
        this.state.room.send(CatchphraseServerInMessages.FinishTeamsSelection, {});
        await this.sleep(500);
        this.setState({ showRoundIntro: true });
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        this.playAudio(audio.SFX.ButtonPress);
        if (this.state.showStartButtons) {
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    async doStartTutorial() {
        this.setState({ showTutorial: true, showStartButtons: false, gameBegun: true });
        this.state.room.send("show_tutorial", {});
        await this.sleep(5000);
        this.setState({ showTutorial: false });
        this.state.room.send(CatchphraseServerInMessages.GameStarted, {});
    }

    async doStartGameSelection() {
        this.stopAudio(audio.VO.Winners);
        this.playAudio(audio.VO.Welcome);
        this.setState({ showGameSection: true, showWinnersSection: false, showPlayAgainButtons: false });
        await this.sleep(500);
        console.log("doStartGameSelection", this.state.roomState.catchphraseData.gameState);
        if (this.state.roomState.catchphraseData.gameState === GameStates.GameSelect) {
            console.log("sending HostReady");
            this.state.room.send(CatchphraseServerInMessages.HostReady);
        }
    }

    async doStartTeamsSelection() {
        this.stopAudio(audio.VO.Welcome);
        this.playAudio(audio.VO.TeamSelect);
        this.setState({ showTeamsSection: true, showGameSection: false });
    }

    async doStartPhrase() {
        console.log("doStartPhrase");
        const catchphraseData = this.state.roomState.catchphraseData;
        if (catchphraseData.round === 1) {
            this.playAudio(audio.Music.RoundOne, 1000);
            this.fadeOutAudio(audio.Music.Menu, 1000);
        }
        else if (catchphraseData.round === 2) {
            this.playAudio(audio.Music.RoundTwo, 1000);
            this.fadeOutAudio(audio.Music.RoundSummary, 1000);
        }
        else if (catchphraseData.round === 3) {
            this.playAudio(audio.Music.RoundThree, 1000);
            this.fadeOutAudio(audio.Music.RoundSummary, 1000);
        }
        this.setState({ showCatchphrase: true, showRoundIntro: false, hideVideo: true, videoLoading: true });
        await this.sleep(500);

        if (catchphraseData.round === 1 && catchphraseData.revealCount === 0) this.playAudio(audio.VO.FirstPhraseIntro);
        else this.playAudio(audio.VO.NextPhraseIntro);
        await this.sleep(3000);

        this.playAudio(audio.VO.PhraseQuip);
        this.setState({ hideVideo: false });
        console.log("doStartPhrase done", this.getCurrentCatchphrase());
    }

    async doStartGridPhrase() {
        const catchphraseData = this.state.roomState.catchphraseData;
        this.setState({ hideVideo: true, videoLoading: true });
        await this.sleep(500);

        if (catchphraseData.revealCount === 1) this.playAudio(audio.VO.BonusBoardIntro);
        else this.playAudio(audio.VO.BonusBoardReturn);
        await this.sleep(2000);

        const previouslyCorrectTeam = catchphraseData.teams.find(t => t.isCorrect);
        if (previouslyCorrectTeam) this.playAudio(audio.VO.OneTeamGridIntro);
        else if (catchphraseData.revealCount < catchphraseData.currentGridReveal.length-1) this.playAudio(audio.VO.AllLockedGridIntro);
        else this.playAudio(audio.VO.AllTeamsGridIntro);

        await this.sleep(1000);
        this.setState({ hideVideo: false });
    }

    async doReavealAnswer(shouldShowAnswer, timesUp, allPassed) {
        if (shouldShowAnswer) this.setState({ showAnswer: true });
        if (timesUp) {
            this.playAudio(audio.SFX.TimesUpBell);
            await this.sleep(500);
            this.playAudio(audio.VO.RoundTimesUp);
        }

        if (allPassed) {
            this.playAudio(audio.SFX.TimesUpBell);
            await this.sleep(500);
            this.playAudio(audio.VO.AllPassed);
        }

        await this.sleep(5000);
        this.setState({ showAnswer: false, hideVideo: true });
        this.state.room.send(CatchphraseServerInMessages.FinishPhrase);
    }

    async doRoundIntro() {
        this.stopAudio(audio.VO.Welcome);
        this.setState({ showTeamsSection: false, showGameSection: false, showRoundIntro: true, showPlayers: true });
        const catchphraseData = this.state.roomState.catchphraseData;
        setTimeout(() => {
            if(catchphraseData.gameState === GameStates.RoundIntro)
            this.state.room.send(CatchphraseServerInMessages.HostReady);
        }, 500);

        if (catchphraseData.round === 1) {
            this.playAudio(audio.VO.RoundOneIntro);
            await this.sleep(13000);
        }
        else if (catchphraseData.round === 2) {
            this.playAudio(audio.VO.RoundTwoIntro);
            await this.sleep(12000);
        }
        else if (catchphraseData.round === 3) {
            this.playAudio(audio.VO.RoundThreeIntro);
            await this.sleep(16000);
        }
        if (catchphraseData.introSkipped) return;
        this.setState({ showRoundIntro: false });
        this.state.room.send(CatchphraseServerInMessages.FinishRoundIntro);
    }

    async doStartRoundSummary() {
        const catchphraseData = this.state.roomState.catchphraseData;
        this.setState({ showCatchphrase: false, showPlayers: false });
        if (catchphraseData.round === 1) this.fadeOutAudio(audio.Music.RoundOne, 1000);
        else if (catchphraseData.round === 2) this.fadeOutAudio(audio.Music.RoundTwo, 1000);
        else if (catchphraseData.round === 3) this.fadeOutAudio(audio.Music.RoundThree, 1000);
        await this.sleep(500);
        this.playAudio(audio.SFX.RoundEnd);
        this.playAudio(audio.Music.RoundSummary);
        this.setState({ showRoundSummary: true });
        await this.sleep(3000);

        if (catchphraseData.round === 1) {
            this.playAudio(audio.VO.RoundOneSummary);
            await this.sleep(10000);
        }
        else if (catchphraseData.round === 2) {
            this.playAudio(audio.VO.RoundTwoSummary);
            await this.sleep(10000);
        }
        else if (catchphraseData.round === 3) {
            this.playAudio(audio.VO.RoundThreeSummary);
            await this.sleep(8000);
        }

        this.setState({ showRoundSummary: false });
        await this.sleep(200);

        if (catchphraseData.round < 3) {
            this.state.room.send(CatchphraseServerInMessages.FinishRoundSummary);
        } else {
            this.setState({ showRoundIntro: false, showWinnersSection: true });
            this.playAudio(audio.SFX.Winners);
            this.playAudio(audio.Music.EndGame);
            await this.sleep(2000);
            this.playAudio(audio.VO.Winners);
            this.setState({ showPlayAgainButtons: true });
            this.state.room.send(CatchphraseServerInMessages.ReachedEnd);
            await this.sleep(500);
            if (catchphraseData.gameState === GameStates.EndGame)
                this.state.room.send(CatchphraseServerInMessages.HostReady);
        }
    }

    doReconnect = () => {
        const token = this.getQueryStringValue("token");
        console.log("Token:", token);

        if (this.state.reconnectTries < 5 && token) {
            console.log("Connecting to room:", token)

            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken, showStartButtons: true });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((roomState) => {
                        console.log("this is the first room state!", roomState);
                        if (roomState.host.id !== room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: roomState.host.uniqueId });
                        this.setState({ roomState: roomState, });
                        room.send("host_joined_game", { gameId });
                        console.log(roomState.catchphraseData.gameState);

                        roomState.catchphraseData.listen("timer", (value) => {
                            //console.log("timer changed", value);
                            const teams = roomState.catchphraseData?.teams;
                            //console.log("teams", teams.map(t => t.answered));
                            const PlayersCanAnswer = teams?.some(t => !t.answered);

                            //if (value === 30 && PlayersCanAnswer) this.playAudio(audio.VO.MidRoundQuip); moved to message from server
                            if (value === 10 && PlayersCanAnswer) this.playAudio(audio.VO.TimesNearlyUp);
                            if (value > 0 && value <= 10) {
                                if (value % 2 === 0) this.playAudio(audio.SFX.Tik);
                                else this.playAudio(audio.SFX.Tok);
                            }
                        });

                        if (roomState.catchphraseData.gameState !== GameStates.MainMenu) this.docatchUp();
                    });

                    room.onStateChange((state) => {
                        console.log("onStateChange", state);
                        this.setState({ roomState: state, });
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "recived for room:", room.name, "message:", message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("begin_tutorial", async (message) => {
                        console.log("begin_tutorial", "recived for room:", room.name, "message:", message);
                        this.doStartTutorial();
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "recived for room:", room.name, "message:", message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });

                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartButtons: false, });
                    });

                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "recived for room:", room.name, "message:", message);
                        const gameState = this.state.roomState.catchphraseData.GameState;
                        console.log("game state... ", gameState);
                        if ((gameState === GameStates.Loading || gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send(CatchphraseServerInMessages.GameStarted, { isTeams: true });
                        }
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartGameSelection, () => {
                        console.log("StartGameSelection", "recived for room:", room.name);
                        this.doStartGameSelection();
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartTeamsSelection, (message) => {
                        console.log("selecting_teams", "recived for room:", room.name, "message:", message);
                        this.doStartTeamsSelection();
                    });

                    room.onMessage(CatchphraseServerOutMessages.TeamSelected, (message) => {
                        console.log("TeamSelected", "recived for room:", room.name, "message:", message);
                        this.playAudio(audio.SFX.ButtonPress);
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartRoundIntro, async (message) => {
                        console.log("StartRound1Intro", "recived for room:", room.name, "message:", message);
                        this.doRoundIntro();
                    });

                    room.onMessage(CatchphraseServerOutMessages.SkipRoundIntro, async (message) => {
                        console.log("SkipIntro", "recived for room:", room.name, "message:", message);
                        this.setState({ showRoundIntro: false });
                        this.stopAudio(audio.VO.RoundOneIntro);
                        this.stopAudio(audio.VO.RoundTwoIntro);
                        this.stopAudio(audio.VO.RoundThreeIntro);
                        this.state.room.send(CatchphraseServerInMessages.FinishRoundIntro);
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartPhrase, async (message) => {
                        console.log("StartPhrase", "recived for room:", room.name, "message:", message);
                        this.doStartPhrase();
                    });

                    room.onMessage(CatchphraseServerOutMessages.TriggerHint, async (message) => {
                        console.log("TriggerHint", "recived for room:", room.name, "message:", message);
                        const PlayersCanAnswer = this.state.roomState.catchphraseData?.teams?.some(t => !t.answered);
                        if (PlayersCanAnswer) this.playAudio(audio.VO.MidRoundQuip);
                    });

                    room.onMessage(CatchphraseServerOutMessages.PlayerGuessed, async (message) => {
                        console.log("PlayerGuessed", "recived for room:", room.name, "message:", message);
                        if (message.wasCorrect) {
                            this.playAudio(audio.SFX.CorrectAnswer);
                            await this.sleep(500);
                            this.playAudio(audio.VO.Correct);
                        }
                        else {
                            this.playAudio(audio.SFX.IncorrectAnswer);
                            await this.sleep(500);
                            this.playAudio(audio.VO.Incorrect);
                        }
                    });

                    room.onMessage(CatchphraseServerOutMessages.BuzzerTimedOut, async (message) => {
                        console.log("buzzer_timed_out", "recived for room:", room.name, "message:", message);
                        this.playAudio(audio.SFX.IncorrectAnswer);
                        await this.sleep(500);
                        this.playAudio(audio.VO.TimedOut);
                    });

                    room.onMessage(CatchphraseServerOutMessages.PlayerPassed, async (message) => {
                        console.log("PlayerPassed", "recived for room:", room.name, "message:", message);
                        this.playAudio(audio.SFX.Pass);
                        //await this.sleep(500);
                        //this.playAudio(audio.VO.Pass);
                    });

                    room.onMessage(CatchphraseServerOutMessages.RevealAnswer, async (message) => {
                        console.log("RevealAnswer", "recived for room:", room.name, "message:", message);
                        this.doReavealAnswer(message.shouldShowAnswer, message.timesUp, message.allPassed);
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartGridPhrase, async (message) => {
                        console.log("StartGridPhrase", "recived for room:", room.name, "message:", message);
                        this.doStartGridPhrase();
                    });

                    room.onMessage(CatchphraseServerOutMessages.StartRoundSummary, async (message) => {
                        console.log("StartRoundSummary", "recived for room:", room.name, "message:", message);
                        this.doStartRoundSummary();
                    });

                    room.onMessage(CatchphraseServerOutMessages.PlayerBuzzedIn, async (message) => {
                        console.log("PlayerBuzzedIn", "recived for room:", room.name, "message:", message);
                        this.stopAudio(audio.VO.MidRoundQuip);
                        this.stopAudio(audio.VO.TimesNearlyUp);
                        this.stopAudio(audio.VO.PhraseQuip);
                        this.playAudio(audio.SFX.Buzzer);
                        if (!this.state.roomState.catchphraseData?.isMoneyDrop) {
                            this.sleep(1500);
                            this.playAudio(audio.VO.BuzzInQuip);
                        }
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "recived for room:", room.name, "message:", message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                        this.state.room.leave(false);
                    });

                    room.onMessage("selecting_teams", (message) => {
                        console.log("selecting_teams", "recived for room:", room.name, "message:", message);
                        this.setState({ showTeamsSection: true });
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                        Sentry.captureMessage(`WS Received: onError`);
                    });

                    room.onLeave(async (code) => {
                        console.log(this.client.id, "left", room.name);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            await this.sleep(1000);
                            this.doReconnect();
                        }
                    });

                    setTimeout(()=> room.send(CatchphraseServerInMessages.HostReady), 500);

                }).catch(async e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    await this.sleep(1000);
                    this.doReconnect();
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState?.isRoku ? "/roku" : "/lobby"}`, hasErrored: true }) //Might need aditional search query url for roku incase the roomstate is null
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    docatchUp() {
        console.log("catching up", this.state.roomState.catchphraseData.gameState);
        //set all show states to false
        this.setState({
            showStartButtons: false,
            showGameSection: false,
            showTeamsSection: false,
            showRoundIntro: false,
            showCatchphrase: false,
            showAnswer: false,
            showRoundSummary: false,
            showWinnersSection: false,
            showPlayAgainButtons: false,
            showPlayers: false,
        });
        audio.Music.Menu.loaded.stop();

        //set the correct show state to true
        switch (this.state.roomState.catchphraseData?.gameState) {  //todo change each state to a doState function becuase currently this will break the game
            case GameStates.MainMenu:
                this.setState({ showStartButtons: true });
                this.playAudio(audio.Music.Menu);
                break;
            case GameStates.GameSelect:
                this.setState({ showGameSection: true, gameBegun: true });
                this.playAudio(audio.Music.Menu);
                break;
            case GameStates.SelectingTeams:
                this.setState({ showTeamsSection: true, gameBegun: true });
                this.playAudio(audio.Music.Menu);
                break;
            case GameStates.RoundIntro:
                this.setState({ showRoundIntro: true, gameBegun: true, showPlayers: true });
                this.doRoundIntro();
                break;
            case GameStates.Playing:
                this.setState({ showCatchphrase: true, gameBegun: true, showPlayers: true, hideVideo: false });
                this.playAudio(audio.Music.RoundOne);
                break;
            case GameStates.RevealAnswer:
                this.setState({ showCatchphrase: true, showAnswer: true, gameBegun: true, showPlayers: true, hideVideo: false });
                this.playAudio(audio.Music.RoundOne);
                break;
            case GameStates.RoundSummary:
                this.setState({ showRoundSummary: true, gameBegun: true });
                this.doStartRoundSummary();
                break;
            case GameStates.EndGame:
                this.setState({ showWinnersSection: true, showPlayAgainButtons: true, gameBegun: true });
                break;
            default:
                console.warn("catch up - invalid state");
                break;
        }
    }


    getTeamPlayers(team) {
        if (!team) return [];
        return team.playerIds.map((id) => { return this.state.roomState.players.get(id) });
    }

    getUnassignedPlayers(players) {
        return players.filter((p) => { return p.catchphraseData?.teamIndex === -1 });
    }

    validateTeams(players, teams) {
        const allPlayersInTeams = players.filter(p => p.connected).every(p => p.catchphraseData?.teamIndex > -1);
        const teamsWithPlayers = teams.filter(t => t.playerIds.length > 0);
        return allPlayersInTeams && teamsWithPlayers.length >= 2;
    }

    onVideoStart = () => {
        console.log("video loaded, starting timer")
        this.state.room?.send(CatchphraseServerInMessages.StartPhraseTimer);
        this.setState({ videoLoading: false });
    }

    getCurrentCatchphrase() {
        const catchphraseData = this.state.roomState?.catchphraseData;
        if (!catchphraseData) return null;
        if (catchphraseData.isGridPhrase)
            return catchphraseData.currentGridCatchphrase;

        const revealCount = catchphraseData.revealCount;
        return catchphraseData.currentCatchphrases.at(revealCount);
    }

    formatTimerDisplay() {
        const totalSeconds = Math.ceil(this.state.roomState.catchphraseData.timer);
        const minuets = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minuets}:${String(seconds).padStart(2, '0')}`;
    }

    //#region render functions
    renderTextDiv(text, additionalStyles) {
        return <>
            <div className={`${styles.text} ${additionalStyles}`}>{text}
                <div className={styles.face}>{text} </div>
            </div>
        </>
    }

    renderSpashBG() {
        //const showSplash = [GameStates.Loading, GameStates.MainMenu].includes(gamesState);
        const showSplash = !this.state.gameBegun || !this.state.connected;
        return <img src={BG} className={`${styles.menuBackground} ${showSplash && styles.show}`} alt="splash"/>
    }

    renderLogoSection() {
        //const centerLogo = [GameStates.Loading, GameStates.MainMenu].includes(gamesState);
        return (
            <div className={`${styles.logoSection} ${this.state.connected && (this.state.gameBegun ? styles.topLeft : styles.menu)}`}>
                <img src={logo} className={styles.logo} alt="logo" />
                {this.renderTextDiv("Connecting...", `${styles.connectingMessage} ${!this.state.connected && styles.show}`)}
            </div>
        )
    }

    renderLicences() {
        const showLicences = this.state.showStartButtons && this.state.connected;
        return (
            <div className={`${styles.licences} ${showLicences && styles.show}`}>
                {this.renderTextDiv("Catch Phrase was created by Pasetta Productions Inc and Steven Radosh. All Rights Reserved")}
                {this.renderTextDiv("Licensed by All3Media International Limited")}
                {this.renderTextDiv("Produced by STV Productions in association with All3Media International Limited together with the inclusion of logos for STV Productions and All3Media International Limited")}
                {this.renderTextDiv(`Catch Phrase Logo, Imagery and Mr Chips character © ${new Date().getFullYear()} All3Media International Limited and STV Productions Limited`)}
            </div>
        )
    }

    renderMainMenu() {
        //const showMainMenu = [GameStates.MainMenu].includes(gamesState) && this.state.connected;
        const showMainMenu = this.state.showStartButtons && this.state.connected;
        return (
            <div className={`${styles.startButtonSection} ${showMainMenu && styles.show}`}>
                <button className={`${styles.mainButton}`} onClick={this.signalStartGame} tabIndex='-1'>
                    {this.renderTextDiv("Start Game")}
                </button>
                <button className={`${styles.mainButton} ${styles.smaller} ${styles.alt}`} onClick={this.signalGoToLobby} tabIndex='-1'>
                    {this.renderTextDiv("Return To Lobby")}
                </button>
                {/*<div className={styles.toggle}>*/}
                {/*    <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />*/}
                {/*    <label htmlFor="checkbox" className={styles.text}>Skip Tutorial*/}
                {/*        <div className={styles.face}>Skip Tutorial</div>*/}
                {/*    </label>*/}
                {/*</div>*/}
            </div>
        )
    }

    //renderGameModeSelection(players) {
    //    //const showGameModeSelection = [GameStates.GameSelect].includes(gameState);
    //    const showGameModeSelection = this.state.showGameSection;
    //    return (
    //        <div className={`${styles.gameSelectionSection} ${showGameModeSelection && styles.show}`} >
    //            <div className={`${styles.titles}`}>
    //                {this.renderTextDiv("CHOOSE A", styles.subTitle)}
    //                {this.renderTextDiv(<>GAME<br />MODE</>, styles.title)}
    //            </div>
    //            <div className={`${styles.buttons}`}>
    //                <button className={`${styles.mainButton}`} onClick={this.signalStartTeams} disabled={players.length < 2} tabIndex='-1'>
    //                    {this.renderTextDiv("Play in Teams")}
    //                </button>
    //                <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.signalStartSolos} tabIndex='-1'>
    //                    {this.renderTextDiv("Play Individually")}
    //                </button>
    //            </div>
    //        </div>
    //    )
    //}

    //renderTeamsSelection(players, teams, timerRunning) {
    //    //const showTeamsSelection = [GameStates.SelectingTeams].includes(gamesState);
    //    const showTeamsSelection = this.state.showTeamsSection;
    //    return (
    //        <div className={`${styles.selectingTeamsSection} ${showTeamsSelection && styles.show}`}>
    //            <div className={`${styles.timer} ${showTeamsSelection && timerRunning && styles.show}`} >{this.formatTimerDisplay()}</div>
    //            <div className={`${styles.titles}`}>
    //                {this.renderTextDiv("CHOOSE YOUR", styles.subTitle)}
    //                {this.renderTextDiv("TEAMS", styles.title)}
    //            </div>
    //            <div className={`${styles.unassigned}`} >
    //                {this.renderUnassignedPlayers(players)}
    //            </div>
    //            <div className={`${styles.teams}`}>
    //                {this.renderTeamSelectionBox(teams.at(0))}
    //                {this.renderTeamSelectionBox(teams.at(2))}
    //            </div>
    //            <div className={`${styles.teams} ${styles.right}`}>
    //                {this.renderTeamSelectionBox(teams.at(1))}
    //                {this.renderTeamSelectionBox(teams.at(3))}
    //            </div>
    //            {this.renderTextDiv(<>Talk amongst yourselves and use your phones<br />to select which Team you want to be in.</>, styles.instruction)}
    //            <button className={`${styles.mainButton} ${this.validateTeams(players, teams) && showTeamsSelection && styles.show}`} onClick={this.signalFinishTeamsSelection} tabIndex='-1'>
    //                {this.renderTextDiv("Confirm Teams", styles.subTitle)}
    //            </button>
    //        </div>
    //    )
    //}

    //renderUnassignedPlayers(players) {
    //    const unassignedPlayers = this.getUnassignedPlayers(players);
    //    return unassignedPlayers.map(player => { return this.renderPlayerTag(player, null) });
    //}

    //renderTeamSelectionBox(team) {
    //    const teamPlayers = this.getTeamPlayers(team);
    //    return <>
    //        <div className={`${styles.team}`} style={{ "--team-colour": team?.colour }}>
    //            {this.renderTextDiv(team?.name.toUpperCase(), styles.title)}
    //            <div className={styles.teamLetter} >{team?.id}</div>
    //            <div className={styles.players}>
    //                {teamPlayers.map(player => { return this.renderPlayerTag(player, team) })}
    //            </div>
    //        </div>
    //    </>
    //}

    //renderPlayerTag(player, team) {
    //    return <div key={player?.id} className={styles.playerTag} style={{ "--team-colour": team ? team.colour : "#44486E" }}>{player?.connected ? player.name : "Not Connected"}</div>
    //}

    //renderRoundIntro(round) {
    //    //const showRoundIntro = [GameStates.RoundIntro].includes(gamesState);
    //    const showRoundIntro = this.state.showRoundIntro;
    //    const subTitle = round === 1 ? "FIRST ROUND" : round === 2 ? "SECOND ROUND" : "FINAL ROUND";
    //    const title = round === 1 ? <>POINT<br />PURSUIT</> : round === 2 ? <>QUICK<br />FIRE</> : <>CRUNCH<br />TIME</>;
    //    return (
    //        <div className={`${styles.roundIntroSection} ${showRoundIntro && styles.show}`} >
    //            <div className={styles.titles}>
    //                {this.renderTextDiv(subTitle, styles.subTitle)}
    //                {this.renderTextDiv(title, styles.title)}
    //            </div>
    //        </div>
    //    )
    //}

    onVideoError = (e) => {
        console.error("video error", e);
    }

    onProgress = (e) => {
        console.log("video progress", e);
    }

    renderCatchphraseSection(catchphraseData, catchphrase, buzzedInPlayer, buzzedInTeam) {
        //const showCatchphrase = [GameStates.Playing, GameStates.RevealAnswer].includes(gamesState);
        const showCatchphrase = this.state.showCatchphrase;
        const showAnswer = this.state.showAnswer;
        const round = catchphraseData.round;
        const roundText = round === 1 ? "FIRST ROUND" : round === 2 ? "SECOND ROUND" : "FINAL ROUND";
        return (
            <div className={`${styles.catchphraseSection} ${showCatchphrase && styles.show}`} >
                <div className={`${styles.timer} ${catchphraseData.timerRunning && showCatchphrase && styles.show} ${catchphraseData.timerPaused && styles.paused}`} >{this.formatTimerDisplay()}</div>
                <div className={`${styles.border} ${buzzedInPlayer && styles.buzzedIn}`} style={{ "--team-colour": buzzedInTeam?.colour ?? "white" }} >
                    <div className={`${styles.videoPlayer}`} >
                        <ReactPlayer
                            ref={this.videoPlayer}
                            url={!this.state.redirect && catchphrase?.video_url_low_res} //checks redirect to remove video when return to lobby
                            className={styles.reactPlayer}
                            width='100%'
                            height='100%'
                            loop={true}
                            playing={!this.state.hideVideo}
                            onStart={this.onVideoStart}
                            muted={true}
                            onError={(e) => console.error("video error", e)}
                            onProgress={(e) => console.log("video progress", e)}
                        />
                        {
                            <div className={styles.gridReveal} >
                                {
                                    catchphraseData?.currentGridReveal.map((reveal, index) => {
                                        return <div key={`bonus-board-${index}`} className={`${styles.reveal} ${(reveal || !catchphraseData?.isGridPhrase) && styles.hide}`} >
                                            <img src={BonusBoard} alt="bonus board segment" />
                                        </div>
                                    })
                                }
                            </div>
                        }
                        <img src={CatchphraseScreen} className={`${styles.catchphraseScreen} ${(this.state.videoLoading || this.state.hideVideo) && styles.show}`} alt="catchphrase" />
                        <div className={`${styles.answer} ${showAnswer && styles.show}`}>{catchphrase?.answers[0]}</div>
                        <div className={`${styles.roundDisplay}`}>{this.renderTextDiv(roundText)}</div>
                    </div>
                </div>
            </div>
        )
    }

    renderPlayerSection(catchphraseData, teams, buzzedInPlayer, buzzedInTeams) {
        //const showPlayers = [GameStates.Playing, GameStates.RevealAnswer].includes(gamesState);
        const showPlayers = this.state.showPlayers;
        return (
            <div className={`${styles.playersSection} ${showPlayers && styles.show}`} >
                {
                    teams.filter(t => t.playerIds.length).map((team) => {
                        return <div className={`${styles.team} ${team.isBuzzedIn && styles.buzzedIn}`} key={team.id} style={{ "--team-colour": team.colour }}>
                            <div className={styles.podium} >
                                <div className={styles.score}>{team.score}
                                    <div className={styles.face}>{team.score}</div>
                                </div>
                            </div>
                            {
                                this.getTeamPlayers(team).filter(p => p).map((player) => {
                                    const lockedOut = this.state.buzzingIn || team?.lockedOut || team?.answered;
                                    return <div key={player.id} className={styles.player} >
                                        <Player player={player} team={team} lockedOut={lockedOut} room={this.state.room} />
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
        )
    }

    //renderRoundSummary(actievTeams, teams, players) {
    //    //const showRoundSummary = [GameStates.RoundSummary].includes(gamesState);
    //    const showRoundSummary = this.state.showRoundSummary
    //    const round = this.state.roomState.catchphraseData.round;
    //    const title = round === 1 ? "ROUND 1 RESULTS" : round === 2 ? "ROUND 2 RESULTS" : "FINAL ROUND RESULTS";
    //    return (
    //        <div className={`${styles.roundSummarySection} ${showRoundSummary && styles.show}`} >
    //            <div className={styles.titles}>
    //                {this.renderTextDiv(title, styles.title)}
    //            </div>

    //            <div className={styles.scoreBars} >
    //                {[...actievTeams].sort((a, b) => a.score - b.score).map(team => {
    //                    const maxScore = Math.max(...teams.map(t => t.score));
    //                    const normalisedScore = Math.max(0, team.score / maxScore);
    //                    const converted = `${(normalisedScore * 20) + 3}vh`;
    //                    return <div className={styles.scoreBar} key={team.id} style={{ "--team-colour": team.colour, "--score": converted }}>
    //                        {this.renderTextDiv(team.name, styles.teamName)}
    //                        {this.renderTextDiv(team.score, styles.score)}
    //                    </div>
    //                })}
    //            </div>

    //            <div className={styles.notablePlayers}>
    //                <div className={styles.player} >
    //                    {this.renderQuickestAnswer(players, teams)}
    //                    {this.renderTextDiv("Quickest Answer")}
    //                </div>
    //                <div className={styles.player} >
    //                    {this.renderMVP(players, teams)}
    //                    {this.renderTextDiv("Most Valuable Player")}
    //                </div>
    //                <div className={styles.player} >
    //                    {this.renderCouchPotato(players, teams)}
    //                    {this.renderTextDiv("Couch Potato")}
    //                </div>
    //            </div>
    //        </div>
    //    )
    //}

    //renderMVP(players, teams) {
    //    const MVP = players.sort((a, b) => b.catchphraseData.score - a.catchphraseData.score)[0]
    //    if (!MVP) return null;
    //    return <Player player={MVP} team={teams[MVP.catchphraseData.teamIndex]} />
    //}

    //renderQuickestAnswer(players, teams) {
    //    const quickestPlayer = players.sort((a, b) => a.catchphraseData.quickestAnswer - b.catchphraseData.quickestAnswer)[0];
    //    if (!quickestPlayer) return null;
    //    return <Player player={quickestPlayer} team={teams[quickestPlayer.catchphraseData.teamIndex]} />
    //}

    //renderCouchPotato(players, teams) {
    //    const couchPlayer = players.sort((a, b) => a.catchphraseData.score - b.catchphraseData.score)[0];
    //    if (!couchPlayer) return null;
    //    return <Player player={couchPlayer} team={teams[couchPlayer.catchphraseData.teamIndex]} />
    //}

    //renderWinnerSection(teams, activeTeams, players) {
    //    //const showWinnersSection = [GameStates.EndGame, GameStates.PlayAgain].includes(gamesState);
    //    const showWinnersSection = this.state.showWinnersSection
    //    const showPlayAgainButtons = this.state.showPlayAgainButtons;

    //    const highestScore = activeTeams.reduce((max, t) => t.score > max ? t.score : max, 0);
    //    const winningTeams = activeTeams.filter(t => t.score === highestScore);
    //    const winningPlayers = players.filter(p => winningTeams.some(w => w.playerIds.includes(p.id)));
    //    const winningTeamsString = winningTeams.map((t) => { return t.name }).join(", ");

    //    return (
    //        <div className={`${styles.winnersSection} ${showWinnersSection && styles.show} ${showPlayAgainButtons && styles.expand}`} >
    //            <div className={styles.titles}>
    //                {this.renderTextDiv("WINNER" + (winningPlayers.length > 1 ? "S" : ""), styles.subTitle)}
    //                {this.renderTextDiv(winningTeamsString.toUpperCase(), styles.title)}
    //            </div>
    //            <img src={Trophy} className={`${styles.trophy}`} alt="trophy" />
    //            <div className={styles.winningPlayers}>
    //                {
    //                    winningPlayers.map((player) => {
    //                        return <div key={player.id} className={styles.player} >
    //                            <Player player={player} team={teams[player.catchphraseData.teamIndex]} />
    //                        </div>
    //                    })
    //                }
    //            </div>
    //            {this.renderTextDiv("THEY’RE THE GREATEST", styles.callout)}
    //        </div>
    //    )
    //}

    renderPlayAgainSection() {
        //const showPlayAgainSection = [GameStates.EndGame].includes(gamesState);
        const showPlayAgainSection = this.state.showPlayAgainButtons;
        return (
            <div className={`${styles.playAgainButtonSection} ${showPlayAgainSection && styles.show}`}>
                <button className={`${styles.mainButton}`} onClick={this.signalNewGame} tabIndex='-1'>
                    {this.renderTextDiv("Play Again")}
                </button>
                <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.signalGoToLobby} tabIndex='-1'>
                    {this.renderTextDiv("Return to lobby")}
                </button>
            </div>
        )
    }

    render() {
        //#region Handle Redirect
        if (this.state.redirect && !(process.env.NODE_ENV === "development" && this.state.hasErrored)) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        //#endregion

        const players = this.state.roomState?.players ? Array.from(this.state.roomState.players.values()) : null;
        const catchphrase = this.getCurrentCatchphrase();
        const catchphraseData = this.state.roomState.catchphraseData;
        const teams = catchphraseData?.teams;
        const activeTeams = catchphraseData?.teams.filter(team => team.playerIds.length > 0);
        const buzzedInPlayer = players?.find(p => p.catchphraseData?.isBuzzedIn);
        const buzzedInTeam = activeTeams?.find(t => t.isBuzzedIn);
        const buzzedInTeams = activeTeams?.filter(t => t.isBuzzedIn);
        const showMoneyDrop = (this.state.showCatchphrase || this.state.showRoundIntro) && catchphraseData.isMoneyDrop;

        return (
            <div>
                <div className={styles.videoPreLoad} >
                    <ReactPlayer
                        url={"https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/480p/Catch+Phrase+Animations+A+Bridge+Too+Far+F5822.mp4"}
                        className={styles.reactPlayer} width='100%' height='100%' loop={true} playing={true} muted={true}
                    />
                </div>
                <div id="gameContainer" className={`${styles.gameContainer} ${this.state.gameBegun && styles.showBgImage}`}>
                    <img src={studioBG} className={`${styles.mainBackground} ${this.state.gameBegun && styles.show}`} alt="background" />
                    {this.renderSpashBG()}
                    {this.renderLogoSection()}
                    {this.renderMainMenu()}
                    {this.renderLicences()}
                    {
                        this.state.connected && catchphraseData &&
                        <>
                            <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />

                            <RoomDetails styles={styles} url={this.getRedirectURL(false)} displayUrl={this.getRedirectURL(true)} muted={this.state.muted} roomId={this.state.roomId}
                                fullscreenAvailable={fullscreenAvailable} onToggleMute={this.toggleMute} onToggleFullScreen={this.toggleFullScreen} onToggleMenu={this.toggleMenu} />

                            <GameModeSelection styles={styles} show={this.state.showGameSection} players={players} onStartTeams={this.signalStartTeams} onStartSolos={this.signalStartSolos} />

                            <TeamsSelection styles={styles} show={this.state.showTeamsSection} players={players} unassignedPlayers={this.getUnassignedPlayers(players)} teams={teams}
                                timerRunning={catchphraseData?.timerRunning} timerText={this.formatTimerDisplay()} showConfirmButton={this.validateTeams(players, teams)} onFinishTeams={this.signalFinishTeamsSelection} />

                            <RoundIntro styles={styles} show={this.state.showRoundIntro} round={catchphraseData?.round} />

                            {this.renderCatchphraseSection(catchphraseData, catchphrase, buzzedInPlayer, buzzedInTeam)}

                            <MoneyDrop styles={styles} show={showMoneyDrop} teams={teams} moneyDropAmount={catchphraseData.moneyDropAmount} moneyDropMax={catchphraseData.moneyDropMax} />

                            {this.renderPlayerSection(catchphraseData, teams, buzzedInPlayer, buzzedInTeams)}
                            
                            <RoundSummary styles={styles} show={this.state.showRoundSummary} round={catchphraseData?.round} activeTeams={activeTeams} teams={teams} players={players} />

                            <WinnerSection styles={styles} showWinners={this.state.showWinnersSection} showPlayAgain={this.state.showPlayAgainButtons} activeTeams={activeTeams} teams={teams} players={players} />

                            <PlayAgainSection styles={styles} show={this.state.showPlayAgainButtons} onNewGame={this.signalNewGame} onGoToLobby={this.signalGoToLobby} />
                        </>
                    }
                    {this.renderDebugButtons(catchphraseData)}
                </div>
            </div>
        );
    }
    //#endregion

    //#region Debug Functions
    renderDebugButtons(catchphraseData) {
        if (!catchphraseData || window.location.hostname !== "localhost") return;
        return (
            <div style={{ position: "absolute", zIndex: 999, pointerEvents: 'initial', fontSize: "10px" }}>
                <button onClick={() => this.setState({ connected: !this.state.connected })}>connect</button>
                <button onClick={() => this.setState({ gameBegun: !this.state.gameBegun })}>gameBegun</button>
                <button disabled >|</button>
                <button onClick={() => this.setState({ showStartButtons: !this.state.showStartButtons })}>showStartButtons</button>
                <button onClick={() => this.setState({ showGameSection: !this.state.showGameSection })}>showGameSelect</button>
                <button onClick={() => this.setState({ showTeamsSection: !this.state.showTeamsSection })}>showTeamsSection</button>
                <button onClick={() => this.setState({ showRoundIntro: !this.state.showRoundIntro })}>showRoundIntro</button>
                <button onClick={() => this.setState({ showCatchphrase: !this.state.showCatchphrase })}>showCatchphrase</button>
                <button onClick={() => this.setState({ showPlayers: !this.state.showPlayers })}>showPlayers</button>
                <button onClick={() => this.setState({ showRoundSummary: !this.state.showRoundSummary })}>showRoundSummary</button>
                <button onClick={() => this.setState({ showWinnersSection: !this.state.showWinnersSection })}>showWinnersSection</button>
                <button onClick={() => this.setState({ showPlayAgainButtons: !this.state.showPlayAgainButtons })}>showPlayAgainButtons</button>

                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Loading })}>Loading</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.MainMenu })}>MainMenu</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Tutorial })}>Tutorial</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.GameSelect })}>GameSelect</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RoundIntro })}>RoundIntro</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.SelectingTeams })}>SelectingTeams</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Playing })}>Playing</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RevealAnswer })}>RevealAnswer</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RoundSummary })}>RoundSummary</button>*/}
                {/*<button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.EndGame })}>EndGame</button>*/}

                <button disabled >|</button>
                <button onClick={() => this.setState({ showAnswer: !this.state.showAnswer })}>showAnswer ({this.state.showAnswer ? "T" : "F"})</button>
                <button onClick={() => this.setState({ hideVideo: !this.state.hideVideo })}>hideVideo ({this.state.hideVideo ? "T" : "F"})</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ isGridPhrase: !catchphraseData.isGridPhrase })}>setIsGridPhrase ({catchphraseData.isGridPhrase ? "T" : "F"})</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ currentGridReveal: [!catchphraseData.currentGridReveal[0], false, false, false] })}>RevealGrid ({catchphraseData.currentGridReveal[0] ? "T" : "F"})</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ isMoneyDrop: !catchphraseData.isMoneyDrop })}>setIsMoneyDrop ({catchphraseData.isMoneyDrop ? "T" : "F"})</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ round: ((catchphraseData.round) % 3) + 1 })}>changeRound({catchphraseData.round})</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ timerRunning: !catchphraseData.timerRunning })}>showTimer ({catchphraseData.timerRunning ? "T" : "F"})</button>
                <button onClick={() => this.testingBuzzIn()}>buzzIn ({this.state.roomState.catchphraseData.teams[0]?.isBuzzedIn ? "T" : "F"})</button>
                <button onClick={() => this.testingPass()}>pass ({this.state.roomState.players.values().toArray()[0]?.passed ? "T" : "F"})</button>
            </div>
        )
    }

    testingSetCatchphraseDataState(state) {
        this.setState({ roomState: { ...this.state.roomState, catchphraseData: { ...this.state.roomState.catchphraseData, ...state } } });
    }

    testingBuzzIn() {
        const team = this.state.roomState.catchphraseData.teams.at(0);
        team.isBuzzedIn = !team.isBuzzedIn;
        const player = this.state.roomState.players.values().toArray()[0];
        player.catchphraseData.isBuzzedIn = !player.catchphraseData.isBuzzedIn;
        this.setState({ roomState: { ...this.state.roomState } });
    }

    testingPass() {
        const player = this.state.roomState.players.values().toArray()[0];
        player.catchphraseData.passed = !player.catchphraseData.passed;
        this.setState({ roomState: { ...this.state.roomState } });
    }
    //#endregion
}