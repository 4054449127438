import { useMemo } from 'react';
import OutlineText from './OutlineText';
import Trophy from '../../images/Trophy.png';
import Player from '../Player';

const WinnerSection = function WinnerSection({ styles, showWinners, showPlayAgain, activeTeams, teams, players }) {
    //const showWinnersSection = [GameStates.EndGame, GameStates.PlayAgain].includes(gamesState);
    const highestScore = activeTeams.reduce((max, t) => t.score > max ? t.score : max, 0);
    const winningTeams = activeTeams.filter(t => t.score === highestScore);
    const winningPlayers = players.filter(p => winningTeams.some(w => w.playerIds.includes(p.id)));
    const winningTeamsString = winningTeams.map((t) => { return t.name }).join(", ");

    return (
        <div className={`${styles.winnersSection} ${showWinners && styles.show} ${showPlayAgain && styles.expand}`} >
            <div className={styles.titles}>
                <OutlineText text={"WINNER" + (winningPlayers.length > 1 ? "S" : "")} styles={styles} className={styles.subTitle} />
                <OutlineText text={winningTeamsString.toUpperCase()} styles={styles} className={styles.title} />
            </div>
            <img src={Trophy} className={`${styles.trophy}`} alt="trophy" />
            <div className={styles.winningPlayers}>
                {
                    winningPlayers.map((player) => {
                        return <div key={player.id} className={styles.player} >
                            <Player player={player} team={teams[player.catchphraseData.teamIndex]} />
                        </div>
                    })
                }
            </div>
            <OutlineText text={"THEY’RE THE GREATEST"} styles={styles} className={styles.callout} />
        </div>
    )
};

export default WinnerSection;