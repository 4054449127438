import { memo } from 'react';
import OutlineText from './OutlineText';

const GameModeSelection = function GameModeSelection({ styles, show, players, onStartTeams, onStartSolos }) {
    //console.log("Rendering GameModeSelection", show, players);
    return (
        <div className={`${styles.gameSelectionSection} ${show && styles.show}`} >
            <div className={`${styles.titles}`}>
                <OutlineText styles={styles} text="CHOOSE A" className={styles.subTitle} />
                <OutlineText styles={styles} text={<>GAME<br />MODE</>} className={styles.title} />
            </div>
            <div className={`${styles.buttons}`}>
                <button className={`${styles.mainButton}`} onClick={onStartTeams} disabled={players.length < 2} tabIndex='-1'>
                    <OutlineText styles={styles} text="Play in Teams" />
                </button>
                <button className={`${styles.mainButton} ${styles.alt}`} onClick={onStartSolos} tabIndex='-1'>
                    <OutlineText styles={styles} text="Play Individually" />
                </button>
            </div>
        </div>
    )
};

export default GameModeSelection;