import { memo } from 'react';
import OutlineText from './OutlineText';
const TeamsSelectionBox = memo(function TeamsSelectionBox({ styles, team, players }) {
    //console.log("Rendering TeamsSelectionBox", team);
    const teamPlayers = team?.playerIds.map((id) => { return players.find(p => p.id === id) });
    return (
        <div className={`${styles.team}`} style={{ "--team-colour": team?.colour }}>
            <OutlineText styles={styles} text={team?.name.toUpperCase()} className={styles.title} />
            <div className={styles.teamLetter} >{team?.id}</div>
            <div className={styles.players}>
                {teamPlayers?.map(player => { return <div key={player?.id} className={styles.playerTag} style={{ "--team-colour": team ? team.colour : "#44486E" }}>{player?.connected ? player.name : "Not Connected"}</div> })}
            </div>
        </div>
    )
});

export default TeamsSelectionBox;