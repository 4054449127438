import { memo } from 'react';
import OutlineText from './OutlineText';

const RoundIntro = memo(function RoundIntro({ styles, show, round }) {
    //console.log("Rendering RoundIntro", show, round);
    const subTitle = round === 1 ? "FIRST ROUND" : round === 2 ? "SECOND ROUND" : "FINAL ROUND";
    const title = round === 1 ? <>POINT<br />PURSUIT</> : round === 2 ? <>QUICK<br />FIRE</> : <>CRUNCH<br />TIME</>;
    return (
        <div className={`${styles.roundIntroSection} ${show && styles.show}`} >
            <div className={styles.titles}>
                <OutlineText styles={styles} text={subTitle} className={styles.subTitle} />
                <OutlineText styles={styles} text={title} className={styles.title} />
            </div>
        </div>
    )
});

export default RoundIntro;