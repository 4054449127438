import { useMemo } from 'react';
import OutlineText from './OutlineText';
import Player from '../Player';

const RoundSummary = function RoundSummary({ styles, show, round, activeTeams, teams, players }) {
    //console.log(`Rendering RoundSummary: ${show}, round: ${round}`);
    const title = round === 1 ? "ROUND 1 RESULTS" : round === 2 ? "ROUND 2 RESULTS" : "FINAL ROUND RESULTS";
    const quickestPlayer = players.sort((a, b) => a.catchphraseData.quickestAnswer - b.catchphraseData.quickestAnswer)[0];
    const MVP = players.sort((a, b) => b.catchphraseData.score - a.catchphraseData.score)[0]
    const couchPlayer = players.sort((a, b) => a.catchphraseData.score - b.catchphraseData.score)[0];
    return (
        <div className={`${styles.roundSummarySection} ${show && styles.show}`} >
            <div className={styles.titles}>
                <OutlineText text={title} styles={styles} className={styles.title} />
            </div>

            <div className={styles.scoreBars} >
                {[...activeTeams].sort((a, b) => a.score - b.score).map(team => {
                    const maxScore = Math.max(...teams.map(t => t.score));
                    const normalisedScore = Math.max(0, team.score / maxScore);
                    const converted = `${(normalisedScore * 20) + 3}vh`;
                    return <div className={styles.scoreBar} key={team.id} style={{ "--team-colour": team.colour, "--score": converted }}>
                        <OutlineText text={team.name} styles={styles} className={styles.teamName} />
                        <OutlineText text={team.score} styles={styles} className={styles.score} />
                    </div>
                })}
            </div>

            <div className={styles.notablePlayers}>
                {quickestPlayer &&
                    <div className={styles.player} >
                        <Player player={quickestPlayer} team={teams[quickestPlayer.catchphraseData.teamIndex]} />
                        <OutlineText text="Quickest Answer" styles={styles} />
                    </div>
                }
                {MVP &&
                    <div className={styles.player} >
                        <Player player={MVP} team={teams[MVP.catchphraseData.teamIndex]} />
                        <OutlineText text="Most Valuable Player" styles={styles} />
                    </div>
                }
                {couchPlayer &&
                    <div className={styles.player} >
                        <Player player={couchPlayer} team={teams[couchPlayer.catchphraseData.teamIndex]} />
                        <OutlineText text="Couch Potato" styles={styles} />
                    </div>
                }
            </div>
        </div>
    )
};

export default RoundSummary;