import { memo, useEffect, useState } from 'react';
import OutlineText from './OutlineText';
import TeamsSelectionBox from './TeamsSelectionBox';

const TeamsSelection = memo(function TeamsSelection({ styles, show, players, unassignedPlayers, teams, timerRunning, timerText, showConfirmButton, onFinishTeams }) {

    //const [render, setRender] = useState(show);
    //const [applyShowStyle, setApplyShowStyle] = useState(show);

    // useEffect(() => {
    //     if (show && !render) {
    //         setRender(true);
    //         setTimeout(() => {
    //             setApplyShowStyle(true);
    //         },50);
    //     } else if (!show && render) {
    //         setApplyShowStyle(false)
    //         setTimeout(() => {
    //             setRender(false);
    //         }, 1000);
    //    }
    // }, [show, render]);

    //if(!render) return null;
    //console.log(`Rendering TeamsSelection: ${show}, players: ${players?.length}, unassignedPlayers: ${unassignedPlayers?.length}, teams: ${teams?.length}, timer Running:${timerRunning}`);

    return (
        //render && <div className={`${styles.selectingTeamsSection} ${applyShowStyle && styles.show}`}>
        <div className={`${styles.selectingTeamsSection} ${show && styles.show}`}>
            <div className={`${styles.timer} ${show && timerRunning && styles.show}`} >{timerText}</div>
            <div className={`${styles.titles}`}>
                <OutlineText styles={styles} text="CHOOSE YOUR" className={styles.subTitle} />
                <OutlineText styles={styles} text="TEAMS" className={styles.title} />
            </div>
            <div className={`${styles.unassigned}`} >
                {unassignedPlayers.map(player => { return <div key={player?.id} className={styles.playerTag} style={{ "--team-colour": "#44486E" }}>{player?.connected ? player.name : "Not Connected"}</div> })}
            </div>
            <div className={`${styles.teams}`}>
                <TeamsSelectionBox styles={styles} team={teams.at(0)} players={players} />
                <TeamsSelectionBox styles={styles} team={teams.at(2)} players={players} />
            </div>
            <div className={`${styles.teams} ${styles.right}`}>
                <TeamsSelectionBox styles={styles} team={teams.at(1)} players={players} />
                <TeamsSelectionBox styles={styles} team={teams.at(3)} players={players} />
            </div>
            <OutlineText styles={styles} text={<>Talk amongst yourselves and use your phones<br />to select which Team you want to be in.</>} className={styles.instruction} />
            <button className={`${styles.mainButton} ${showConfirmButton && show && styles.show}`} onClick={onFinishTeams} tabIndex='-1'>
                <OutlineText styles={styles} text="Confirm Teams" />
            </button>
        </div>
    )
});

export default TeamsSelection;